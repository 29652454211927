<script setup lang="ts">
import Intercom from '@intercom/messenger-js-sdk';

const user = await useSupabaseUser();
  const userOrganization = useOrganizationStore();
  const logger = useLogger();
  const runtimeConfig = useRuntimeConfig();

  const initializeIntercom = async () => {
    const intercomAppId = runtimeConfig.public.intercom.appId;

    if (!intercomAppId) {
      logger.warn('Intercom not initialized: appId is not set in runtime config', '#intercom');
      return;
    }

    if (!user.value) {
      logger.warn('Intercom not initialized: user is not signed in', '#intercom');
      return;
    }

    if (user.value) {
      try {
        // Call the verify-intercom-hmac endpoint to get the user hash
        const userHash = await $fetch('/api/v2/auth/verify-intercom-hmac', {
              headers: useRequestHeaders(['cookie']),
            })

        

        useLogger().debug(`Intercom user hash: ${userHash}`, '#intercom', '#user', { userHash });

        const createdAtTimestamp = user.value.created_at ? Math.floor(new Date(user.value.created_at).getTime() / 1000) : undefined;

        let company = {};
        
        if (userOrganization && userOrganization.currentOrganization) {
          company = {
            id: userOrganization.currentOrganization?.id,
            name: userOrganization?.currentOrganization?.name,
            created_at: Math.floor(new Date(userOrganization.currentOrganization.created_at).getTime() / 1000),
          };

          logger.debug('Intercom company context found', '#intercom', '#company', { company });
        }

         // Get the user profile
         const userProfileStore = useUserProfileStore()
         const { firstName, lastName, profile } = storeToRefs(userProfileStore)
         await userProfileStore.fetchProfile()

        const intercomUser = {
          app_id: intercomAppId,
          user_id: user.value.id,
          name: `${firstName?.value} ${lastName?.value}`,
          email: user.value.email,
          created_at: createdAtTimestamp,
          company,
          user_hash: userHash, // Verifies the user's identity with intercom
          // Additional profile data
          first_name: firstName?.value,
          last_name: lastName?.value,
          profile_complete: profile?.value?.profile_complete,
          onboarding_completed: profile?.value?.onboarding_completed,
          username: profile?.value?.username,
          avatar: profile?.value?.avatar_url,
          phone: profile?.value?.phone_mobile || undefined,
          phone_landline: profile?.value?.phone_landline || undefined,
        };

        logger.debug('Intercom initialising', '#intercom', '#user', { intercomUser });

         Intercom(intercomUser);

        logger.debug('Intercom initialised', '#intercom', '#user', { intercomUser });
      } catch (error) {
        logger.error('Failed to initialize Intercom', '#intercom', { error });
      }
    } else {
      logger.debug('Intercom not initialised, no user', '#intercom');
    }
  };

onMounted(() => {
   initializeIntercom();
});



</script>

<template>
    <div id="intercom-container"></div>
</template>
