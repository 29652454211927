<script setup lang="ts">
import { storeToRefs } from 'pinia'

  const emit = defineEmits(['toggle'])
  const appConfig = useAppConfig()
  const store = useSideNavStore()
  const orgStore = useOrganizationStore()

  const { isOpen, isPinned, sidebarVisible } = storeToRefs(useSideNavStore())

  // Initialize top-level links
  store.setTopLevelLinks(appConfig.navigationRootLinks)

  // Set showBetaItems based on app config or user role
  const isPlatformAdmin = useIsPlatformAdmin()
  store.setShowBetaItems(appConfig.alwaysShowBetaItemsInNav || isPlatformAdmin)

  // Emit toggle event
  watch(isOpen, (newVal) => {
  emit('toggle', newVal)
  })

  const handleNavToggle = (isOpen: boolean) => {
  useLogger().debug('handleNavToggle', isOpen)
  };

// Initialize organization store
await orgStore.fetchuserOrganizationRoles()
  const { activeOrgName } = storeToRefs(orgStore)

  // Initialize online status tracking
  const userUserOnlineStatus = useUserOnlineStatusStore()

  // onMounted only runs on the client side, so window is available.
  onMounted(() => {
  userUserOnlineStatus.initializeOnlineStatus()
    window.addEventListener('beforeunload', userUserOnlineStatus.cleanupOnlineStatus)
  })
  onBeforeUnmount(() => {
  userUserOnlineStatus.cleanupOnlineStatus()
    window.removeEventListener('beforeunload', userUserOnlineStatus.cleanupOnlineStatus)
  })
</script>

<template>
  <div class="flex flex-col min-h-screen bg-background">
    <main class="flex-grow">
      <div class="">
        <SlLayoutSideNavDual @toggle="handleNavToggle" />
        <div
          class="transition-all duration-300 z-30"
          :class="{
            'ml-80': isPinned && sidebarVisible,
            'ml-20': !isPinned && sidebarVisible,
            'relative': !isPinned,
          }"
        >
          <div>
            <NuxtPage />
          </div>
        </div>
        <client-only>
          <BotLoader />
        </client-only>
        <client-only>
          <SupportIntercom />
        </client-only>
      </div>
    </main>

    <footer class="bg-background text-muted-foreground py-4 mt-auto text-xs">
      <div class="container mx-auto px-4">
        <div class="flex flex-col md:flex-row justify-between items-center">
          <p class="text-sm mb-2 md:mb-0">
            &copy; {{ new Date().getFullYear() }} Soil Link. All rights reserved.
          </p>
          <p class="text-xs mb-2 md:mb-0 opacity-80">
            Signed in as <span class="font-semibold">{{ activeOrgName }}</span>
          </p>
          <div class="flex space-x-4">
            <NuxtLink
              to="https://usesoil.link/terms"
              target="_blank"
              class="hover:text-current transition-colors duration-300"
            >
              Terms
            </NuxtLink>
            <NuxtLink
              to="https://usesoil.link/privacy"
              target="_blank"
              class="hover:text-current transition-colors duration-300"
            >
              Privacy
            </NuxtLink>
            <NuxtLink
              to="https://usesoil.link/contact"
              target="_blank"
              class="hover:text-current transition-colors duration-300"
            >
              Contact
            </NuxtLink>
          </div>
        </div>
      </div>
      <div class="flex flex-col md:flex-row justify-center items-center opacity-20 mt-8">
        <div class="mr-4">
          {{ useRuntimeConfig().public.environment }} / v{{
            useRuntimeConfig().public.release
          }}
        </div>
        <ErrorTestButton />
      </div>
    </footer>
  </div>
</template>
